<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/tracings`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-card>
      <h3>
          {{tracing.name}}
      </h3>
      <hr>
      <h4 class="mt-1">
          {{$t("Messages.ChooseDeviceAdd")}}
      </h4>
      <b-form
        class=""
        @submit.prevent
        v-if="$store.state.session.AppActiveUser().userRole !== 'TP'"
      >
        <validation-observer ref="membersValidation">
        <b-row align-v="center">
          <b-col cols="12" md="4">
            <b-form-group :label="$tc('Labels.Trackers')" label-for="device">
              <validation-provider #default="{ errors }" name="device" rules="required">
                <b-form-select name="device" :state="errors.length > 0 ? false:null" :options="trackers" v-model="tracker_uuid" value-field="uuid" text-field="name" :clearable="false">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{$tc('Labels.SelectTracker')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-button variant="primary" @click="validationTracker" :title="$t('Labels.Add')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
              <feather-icon icon="PlusIcon"/>
            </b-button>
          </b-col>
        </b-row>
        </validation-observer>
      </b-form>
      <b-form
        class=""
        @submit.prevent
      >
      <validation-observer ref="emailValidation">
      <b-row align-v="center">
        <b-col cols="12" md="4">
          <b-form-group
            :label="$t('Labels.SendInvitation')"
            label-for="email"
          >
          <validation-provider
              #default="{ errors }"
              name="email"
              rules="email"
            >
            <b-form-input
              id="email"
              v-model="email"
              name="email"
              :placeholder="$t('Email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-button variant="primary" @click="validationEmail" :title="$t('Labels.Add')" v-b-tooltip.hover="{ variant: 'primary', placement: 'right' }">
            <feather-icon icon="PlusIcon"/>
          </b-button>
        </b-col>
      </b-row>
      </validation-observer>
      </b-form>
      <b-row>
        <b-col cols="12" md="5">
          <b-card header-tag="header">
            <template #header>
              <h4>
                {{$t("Labels.Members")}}
              </h4>
            </template>
            <b-list-group>
              <draggable v-model="members" @end="dragEnd" draggable=".item" class="list-group">
                <b-list-group-item class="item" v-for="(item, index) in members" :key="index">
                  <span class="align-middle">{{item.tracker.name}}</span>
                  <b-button variant="danger" class="btn-icon rounded-circle float-right align-middle" @click="doDelete(item.tracker_uuid)" :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'right' }">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-list-group-item>
              </draggable>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BForm,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BFormSelectOption,
  BFormInput
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'
import draggable from 'vuedraggable'

const DATA = {
  code: 200,
  data: {
    name: 'Familia',
    description: 'Mi familia',
    members: [
      'juan@correo.com',
      'maria@correo.com',
      'patricio@correo.com'
    ]
  },
  msg: 'Ok'
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BFormSelectOption,
    draggable,
    BFormInput
  },
  data () {
    return {
      tracker_uuid: null,
      members: [],
      required,
      DATA,
      uuid: this.$route.params.uuid,
      tracing: {},
      trackers: [],
      email: ''
    }
  },
  methods: {
    async getTrackers () {
      const params = {
        url: 'tracker/list',
        method: 'GET'
      }
      await request(params).then(response => {
        this.trackers = response.data.data
      }).catch(() => {
        this.trackers = []
      })
    },
    async getTracing () {
      const params = {
        url: `tracing/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.tracing = data.data.data[0]
        this.members = data.data.data[0].tracing_trackers
      })
    },
    async addMember () {
      const params = {
        url: `tracing/${this.tracker_uuid}/${this.uuid}`,
        method: 'POST',
        customMessages: true
      }
      await request(params).then(response => {
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.AddSuccess'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
        const select = this.trackers.filter(item => {
          return item.uuid === this.tracker_uuid
        })
        this.members.push({
          tracker_uuid: select[0].uuid,
          tracker: select[0]
        })
        this.tracker_uuid = null
        this.$refs.membersValidation.reset()
      }).catch(error => {
        if (error.response.data.code === 409) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.AlreadyExist'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else if (error.response.data.code === 428) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.FailMessage'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else if (error.response.data.code === 404) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.TrackerNotFound'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    validationTracker () {
      this.$refs.membersValidation.validate().then(success => {
        if (success) {
          this.addMember()
        }
      })
    },
    validationEmail () {
      this.$refs.emailValidation.validate().then(success => {
        if (success) {
          this.addMemberEmail()
        }
      })
    },
    async addMemberEmail () {
      const params = {
        url: `tracing/${this.uuid}`,
        method: 'POST',
        customMessages: true,
        params: {
          email: this.email
        }
      }
      await request(params).then(response => {
        this.email = ''
        this.$swal({
          title: this.$t('Messages.Success'),
          text: this.$t('Messages.RequestSend'),
          timer: 5000,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        })
      }).catch(error => {
        if (error.response.data.code === 409) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.AlreadyExist'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else if (error.response.data.code === 428) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.WaitingInfo'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        } else if (error.response.data.code === 404) {
          this.$swal({
            title: this.$t('Messages.Fail'),
            text: this.$t('Messages.UserNotFound'),
            timer: 5000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
        }
      })
    },
    async doDelete (idTracker) {
      const params = {
        url: `tracing/${idTracker}/${this.uuid}`,
        method: 'DELETE'
      }
      await request(params).then(response => {
        this.members = this.members.filter(item => item.tracker_uuid !== idTracker)
      })
    },
    async dragEnd (e) {
      const uuids = this.members.map(item => { return item.tracker_uuid })
      const params = {
        url: `tracing/order/${this.uuid}`,
        method: 'PUT',
        customMessages: true,
        params: {
          trackers_uuids: uuids.join(',')
        }
      }
      await request(params)
    }
  },
  created () {
    if (this.$store.state.session.AppActiveUser().userRole !== 'TP') {
      this.getTrackers()
    }
    this.getTracing()
  }
}
</script>
